<template>
  <div class="page-bookrank common-page">
    <div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack">
      </div>
      <h1 class="page-title">
        Ranking
      </h1>
      <div class="btn-act"></div>
    </div>
    <div class="page-con nop">
      <div class="ranklist-con">
        <div class="mtab">
          <div class="otab" :class="{cur: curMtabId === item.id}" v-for="item of mtabData" :key="item.id" @click="clickMTab(item.id)">
            {{ item.name }}
          </div>
        </div>
        <div class="stab">
          <div class="otab" :class="{cur: curSTabId === 1}" @click="clickSTab(1)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="14" height="16" viewBox="0 0 14 16"><g><path d="M0,2L0,14.1097C0,14.9127,0.898822,15.3882,1.56269,14.9363L5.87462,12.0013C6.55368,11.5391,7.44632,11.5391,8.12538,12.0013L12.4373,14.9363C13.1012,15.3882,14,14.9127,14,14.1097L14,2C14,0.895431,13.1046,0,12,0L2,0C0.895431,0,0,0.895431,0,2Z" fill-opacity="1"/></g></svg>
            Recommend
          </div>
          <div class="otab" :class="{cur: curSTabId === 2}" @click="clickSTab(2)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="14.305458068847656" height="16.680959701538086" viewBox="0 0 14.305458068847656 16.680959701538086"><g><path d="M10.3905,4.22755C10.5869,3.84531,11.0561,3.69492,11.4381,3.89155C11.5638,3.95621,11.6694,4.05386,11.7437,4.17403C12.1213,4.78461,12.3657,5.16924,12.4649,5.31024C14.0236,7.52684,14.4399,8.58673,14.2701,10.9108C14.0514,13.9047,12.1828,15.8455,9.48824,16.5067C9.18256,16.5817,8.87113,16.6381,8.55762,16.6759C8.17684,16.7218,7.83098,16.4503,7.78511,16.0695C7.76074,15.8671,7.82651,15.6642,7.96499,15.5147C8.17143,15.2917,8.30639,15.1221,8.36315,15.0236C8.53148,14.7314,8.62123,14.4004,8.62123,14.0547C8.62123,12.9808,7.75067,12.1102,6.67678,12.1102C5.6029,12.1102,4.73234,12.9808,4.73234,14.0547C4.73234,14.298,4.77672,14.5341,4.86215,14.7552C4.90471,14.8653,5.01369,15.0504,5.18658,15.2928C5.26339,15.4191,5.3018,15.5691,5.3018,15.7427C5.3018,16.0031,5.18658,16.2027,5.04895,16.3126C4.88366,16.4445,4.57666,16.4816,4.37365,16.4283C3.98806,16.3271,3.69187,16.2369,3.47785,16.1543C1.40086,15.3524,0.160082,13.6504,0.0111324,10.8977C-0.144499,8.02168,1.34709,6.10217,3.51815,4.53482C4.73234,3.65827,5.57718,3.2674,6.31866,2.40142C6.58831,2.08649,7.02045,1.41011,7.59341,0.395606C7.8046,0.0214345,8.27915,-0.110585,8.65322,0.10068C8.77827,0.1713,8.88132,0.27516,8.95096,0.400752C9.62515,1.61664,9.89571,2.82114,9.74544,3.9994C9.67646,4.54026,9.57641,5.10932,9.44539,5.70676C9.75408,5.34202,10.0708,4.84859,10.3905,4.22755C10.3905,4.22755,10.3905,4.22755,10.3905,4.22755Z" /></g></svg>
            Hot
          </div>
          <div class="otab" :class="{cur: curSTabId === 3}" @click="clickSTab(3)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="16.381792068481445" height="15.73003101348877" viewBox="0 0 16.381792068481445 15.73003101348877"><g><path d="M4.78377,15.5542C4.03692,15.9469,3.11319,15.6597,2.72055,14.9129C2.5642,14.6155,2.51024,14.2749,2.56704,13.9437C2.56704,13.9437,3.21773,10.1499,3.21773,10.1499C3.21773,10.1499,0.461372,7.46312,0.461372,7.46312C-0.142839,6.87416,-0.155202,5.9069,0.433758,5.30269C0.668285,5.06209,0.975586,4.90551,1.30809,4.8572C1.30809,4.8572,5.11728,4.30369,5.11728,4.30369C5.11728,4.30369,6.8208,0.851976,6.8208,0.851976C7.19422,0.095339,8.11032,-0.215318,8.86695,0.158105C9.16825,0.306804,9.41213,0.550679,9.56083,0.851976C9.56083,0.851976,11.2643,4.30369,11.2643,4.30369C11.2643,4.30369,15.0735,4.8572,15.0735,4.8572C15.9085,4.97853,16.4871,5.75379,16.3657,6.58879C16.3174,6.92129,16.1609,7.22859,15.9203,7.46312C15.9203,7.46312,13.1639,10.1499,13.1639,10.1499C13.1639,10.1499,13.8146,13.9437,13.8146,13.9437C13.9572,14.7753,13.3987,15.5651,12.5671,15.7078C12.2359,15.7646,11.8953,15.7106,11.5979,15.5542C11.5979,15.5542,8.19081,13.7631,8.19081,13.7631C8.19081,13.7631,4.78377,15.5542,4.78377,15.5542C4.78377,15.5542,4.78377,15.5542,4.78377,15.5542Z" /></g></svg>
            Promising
          </div>
          <div class="otab" :class="{cur: curSTabId === 4}" @click="clickSTab(4)">
            <svg t="1726473300165" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12220" width="200" height="200"><path d="M512 1003.52c102.4 0 188.416-86.016 188.416-188.416v-12.288H323.584v12.288c0 102.4 86.016 188.416 188.416 188.416zM512 20.48C303.104 20.48 135.168 192.512 135.168 397.312c0 139.264 77.824 258.048 188.416 323.584h376.832c110.592-65.536 188.416-188.416 188.416-327.68 0-204.8-172.032-372.736-376.832-372.736z m135.168 339.968l-167.936 118.784c-8.192 4.096-16.384 8.192-28.672 8.192-16.384 0-28.672-8.192-40.96-20.48l-57.344-81.92c-12.288-20.48-8.192-49.152 12.288-65.536 20.48-16.384 53.248-12.288 65.536 12.288l32.768 40.96 131.072-90.112c20.48-16.384 53.248-12.288 65.536 12.288 16.384 20.48 12.288 49.152-12.288 65.536z" p-id="12221"></path></svg>
            Best
          </div>

        </div>
        <div class="rank-list ">
          <div class="one-book fx-vb" v-for="(item,index) of bookList" :key="item.id">
            <div class="rank-idx" :class="[`idx-` + (index+1)]">
              {{ index+1 }}
            </div>
            <div class="cover">
              <img loading="lazy" :src="item.imgUrl" alt="">
            </div>
            <div class="detail">
              <h3>{{ item.name }}</h3>
              <p class="hot-num">
                {{ item.hotNum }}
              </p>
              <div class="info fx-vb">
                <div class="auther">
                  {{ item.auth }}
                </div>
                <div class="tag">
                  {{ item.tag }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BookRank',
  data() {
    return {
      curMtabId: 1,
      curSTabId:1,
      mtabData: [
        {
          id:1,
          name: 'All'
        },
        {
          id:2,
          name: 'WereWolf'
        },
        {
          id:3,
          name: 'Rommance'
        },
        {
          id:4,
          name: 'New'
        },
        {
          id:5,
          name: 'CEO'
        },
        {
          id:6,
          name: 'XXX'
        },
        {
          id:7,
          name: 'XXXxxx'
        },
      ],
      bookList: [
        {
          id:1,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby My Stepbrother's baby My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'Tommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
        {
          id:2,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'Tommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
        {
          id:3,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'Tommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
        {
          id:4,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'TommyTommyTommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
        {
          id:5,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'Tommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
        {
          id:6,
          imgUrl: '/images/book-img.png',
          name: "My Stepbrother's baby",
          desc: "This is a Book About KongFu, it's imagic~ so fun! so Good!",
          tag: 'Romace',
          auth: 'Tommy JK',
          score: 9.7,
          progress: 5,
          hotNum:1001,
        },
      ]
    }
  },
  components: {
  },
  computed: {
  }, 
  methods: {
    pageBack() {
      this.$router.push({
        name: 'HomePage'
      })
    },
    clickSTab(id) {
      console.log(id);
      this.curSTabId = id
    },
    clickMTab(id) {
      this.curMtabId = id
    }
  },
  mounted() {
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>